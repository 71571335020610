import Almanya from "../images/countries/Almanya.png";
import Angola from "../images/countries/Angola.png";
import Arjantin from "../images/countries/Arjantin.png";
import Arnavutluk from "../images/countries/Arnavutluk.png";
import Avusturya from "../images/countries/Avusturya.png";
import Azerbaycan from "../images/countries/Azerbaycan.png";
import Belçika from "../images/countries/Belçika.png";
import BosnaHersek from "../images/countries/Bosna-Hersek.png";
import Brezilya from "../images/countries/Brezilya.png";
import Bulgaristan from "../images/countries/Bulgaristan.png";
import Cad from "../images/countries/Cad.png";
import ÇekCumhuriyeti from "../images/countries/Çek Cumhuriyeti.png";
import Cezayir from "../images/countries/Cezayir.png";
import Danimarka from "../images/countries/Danimarka.png";
import Ekvador from "../images/countries/Ekvador.png";
import Fas from "../images/countries/Fas.png";
import FildişiSahili from "../images/countries/Fildişi Sahili.png";
import Filipinler from "../images/countries/Filipinler.png";
import Finlandiya from "../images/countries/Finlandiya.png";
import Fransa from "../images/countries/Fransa.png";
import Gabon from "../images/countries/Gabon.png";
import Gambiya from "../images/countries/Gambiya.png";
import Gana from "../images/countries/Gana.png";
import GineBissau from "../images/countries/Gine-Bissau.png";
import Gine from "../images/countries/Gine.png";
import Guadeloupe from "../images/countries/Guadeloupe.png";
import GüneyAfrika from "../images/countries/Güney Afrika.png";
import GüneyKore from "../images/countries/Güney Kore.png";
import Gürcistan from "../images/countries/Gürcistan.png";
import Haiti from "../images/countries/Haiti.png";
import Hollanda from "../images/countries/Hollanda.png";
import Honduras from "../images/countries/Honduras.png";
import Hırvatistan from "../images/countries/Hırvatistan.png";
import Jamaika from "../images/countries/Jamaika.png";
import Kamerun from "../images/countries/Kamerun.png";
import Karadağ from "../images/countries/Karadağ.png";
import Kazakistan from "../images/countries/Kazakistan.png";
import Kolombiya from "../images/countries/Kolombiya.png";
import KongoDC from "../images/countries/Kongo DC.png";
import Kongo from "../images/countries/Kongo.png";
import Kosova from "../images/countries/Kosova.png";
import KostaRika from "../images/countries/Kosta Rika.png";
import Kırgızistan from "../images/countries/Kırgızistan.png";
import KuzeyMakedonya from "../images/countries/Kuzey Makedonya.png";
import Litvanya from "../images/countries/Litvanya.png";
import Lüksemburg from "../images/countries/Lüksemburg.png";
import Macaristan from "../images/countries/Macaristan.png";
import Mali from "../images/countries/Mali.png";
import Mısır from "../images/countries/Mısır.png";
import Nijerya from "../images/countries/Nijerya.png";
import Norveç from "../images/countries/Norveç.png";
import Özbekistan from "../images/countries/Özbekistan.png";
import Paraguay from "../images/countries/Paraguay.png";
import Polonya from "../images/countries/Polonya.png";
import Portekiz from "../images/countries/Portekiz.png";
import Romanya from "../images/countries/Romanya.png";
import Senegal from "../images/countries/Senegal.png";
import Slovakya from "../images/countries/Slovakya.png";
import Slovenya from "../images/countries/Slovenya.png";
import Surinam from "../images/countries/Surinam.png";
import Sırbistan from "../images/countries/Sırbistan.png";
import Tunus from "../images/countries/Tunus.png";
import Türkiye from "../images/countries/Türkiye.png";
import Uganda from "../images/countries/Uganda.png";
import Uruguay from "../images/countries/Uruguay.png";
import Venezuela from "../images/countries/Venezuela.png";
import YeşilBurunAdaları from "../images/countries/Yeşil Burun Adaları.png";
import Yunanistan from "../images/countries/Yunanistan.png";
import Zimbabve from "../images/countries/Zimbabve.png";
import İngiltere from "../images/countries/İngiltere.png";
import İran from "../images/countries/İran.png";
import İspanya from "../images/countries/İspanya.png";
import İsrail from "../images/countries/İsrail.png";
import İsveç from "../images/countries/İsveç.png";
import İsviçre from "../images/countries/İsviçre.png";
import İtalya from "../images/countries/İtalya.png";

export const countries = {
  Almanya: Almanya,
  Angola: Angola,
  Arjantin: Arjantin,
  Arnavutluk: Arnavutluk,
  Avusturya: Avusturya,
  Azerbaycan: Azerbaycan,
  Belçika: Belçika,
  "Bosna-Hersek": BosnaHersek,
  Brezilya: Brezilya,
  Bulgaristan: Bulgaristan,
  Cad: Cad,
  "Çek Cumhuriyeti": ÇekCumhuriyeti,
  Cezayir: Cezayir,
  Danimarka: Danimarka,
  Ekvador: Ekvador,
  Fas: Fas,
  "Fildişi Sahili": FildişiSahili,
  Filipinler: Filipinler,
  Finlandiya: Finlandiya,
  Fransa: Fransa,
  Gabon: Gabon,
  Gambiya: Gambiya,
  Gana: Gana,
  "Gine-Bissau": GineBissau,
  Gine: Gine,
  Guadeloupe: Guadeloupe,
  "Güney Afrika": GüneyAfrika,
  "Güney Kore": GüneyKore,
  Gürcistan: Gürcistan,
  Haiti: Haiti,
  Hollanda: Hollanda,
  Honduras: Honduras,
  Hırvatistan: Hırvatistan,
  Jamaika: Jamaika,
  Kamerun: Kamerun,
  Karadağ: Karadağ,
  Kazakistan: Kazakistan,
  Kolombiya: Kolombiya,
  "Kongo DC": KongoDC,
  Kongo: Kongo,
  Kosova: Kosova,
  "Kosta Rika": KostaRika,
  "Kuzey Makedonya": KuzeyMakedonya,
  Kırgızistan: Kırgızistan,
  Litvanya: Litvanya,
  Lüksemburg: Lüksemburg,
  Macaristan: Macaristan,
  Mali: Mali,
  Mısır: Mısır,
  Nijerya: Nijerya,
  Norveç: Norveç,
  Özbekistan: Özbekistan,
  Paraguay: Paraguay,
  Polonya: Polonya,
  Portekiz: Portekiz,
  Romanya: Romanya,
  Senegal: Senegal,
  Slovakya: Slovakya,
  Slovenya: Slovenya,
  Surinam: Surinam,
  Sırbistan: Sırbistan,
  Tunus: Tunus,
  Türkiye: Türkiye,
  Uganda: Uganda,
  Uruguay: Uruguay,
  Venezuela: Venezuela,
  "Yeşil Burun Adaları": YeşilBurunAdaları,
  Yunanistan: Yunanistan,
  Zimbabve: Zimbabve,
  İngiltere: İngiltere,
  İran: İran,
  İspanya: İspanya,
  İsrail: İsrail,
  İsveç: İsveç,
  İsviçre: İsviçre,
  İtalya: İtalya,
};
