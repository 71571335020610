import { useStaticQuery, graphql } from "gatsby"

export const useStatNames = () => {
  const { allStatNamesJson } = useStaticQuery(graphql`
  query {
    allStatNamesJson(sort: {fields: rank}, filter: {visible: {eq: true}}) {
      nodes {
        slug
        order
        name
      }
    }
  }
  `);

  return allStatNamesJson.nodes;
}
