import React from "react";

function TabularButton({ isActive, onClick, label }) {
  return (
    <button
      className={`text-xs font-bold w-1/2 h-8 ${
        isActive ? "bg-indigo-300 shadow" : "bg-indigo-200"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default TabularButton;
