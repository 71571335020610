import React from "react";
import { FaArrowRight, FaInfoCircle, FaVideo } from "react-icons/fa";
import { navigate, Link } from "gatsby";

import ExternalLink from "../components/ExternalLink";
import { getMatchLink } from "../util/beinConnectUtil";
import { getDate, getTime } from "../util/dateUtil";
import { logos } from "../util/logos";
import { getMatchURL } from "../util/urlUtil";

const SimpleFixture = ({ fixture, header, showRounds = true, fixtureUrl }) => {
  let curDay, curRound;

  return (
    <div className="flex flex-col border-2 border-solid border-slate-200 shadow-md w-full dark:text-white">
      <div className="flex flex-col text-pink-500 items-center font-semibold my-1">
        {header}
      </div>
      {fixture.length < 1 ? (
        <div className="text-xs ml-3"> Maç bulunmamaktadır </div>
      ) : (
        fixture.map((f, ind) => {
          const tempDay = getDate(f.date);
          const dateChanged = curDay !== tempDay;
          const roundChanged = curRound !== f.round;
          curDay = tempDay;
          curRound = f.round;
          const matchEnded = f.homeScore !== null;

          return (
            <div key={ind}>
              {showRounds && roundChanged && (
                <div className="flex flex-col text-pink-500 items-center font-semibold my-1">
                  {curRound}. Hafta
                </div>
              )}
              {dateChanged && (
                <div className="flex justify-center font-semibold py-2">
                  {curDay}
                </div>
              )}
              <Link to={getMatchURL(f.homeFullName, f.awayFullName)}>
                <div className="relative flex justify-center py-4 border-0 border-b-2 border-solid border-gray-300 text-xs hover:bg-gradient-to-r hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 hover:text-white">
                  <div className="flex basis-full justify-end items-center mx-3">
                    <span className="font-semibold mr-1">
                      {f.homeShortName}
                    </span>
                    <img
                      width="20px"
                      height="20px"
                      src={logos[f.homeSlug]}
                      alt={f.homeFullName}
                    />
                  </div>
                  {!matchEnded ? (
                    <div className="flex items-center p-1 border border-solid border-gray-300 text-xs">
                      {getTime(new Date(f.date)) === "03:00" ? (
                        <span title="Tarihi Belli Değil">TBD</span>
                      ) : (
                        getTime(new Date(f.date))
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center ">
                      <span className="bg-indigo-600 font-semibold text-white w-5 h-5 flex justify-center items-center mr-0.5">
                        {f.homeScore}
                      </span>
                      <span className="bg-indigo-600 font-semibold text-white w-5 h-5 flex justify-center items-center">
                        {f.awayScore}
                      </span>
                    </div>
                  )}
                  <div className="flex basis-full	items-center mx-3">
                    <img
                      width="20px"
                      height="20px"
                      src={logos[f.awaySlug]}
                      alt={f.awayFullName}
                    />
                    <span className="font-semibold ml-1">
                      {f.awayShortName}
                    </span>
                    {f.oldDate !== null && (
                      <FaInfoCircle
                        className="ml-2 h-5 w-5"
                        title={`Maç ${getDate(
                          new Date(f.oldDate)
                        )} tarihinden ertelenmiştir`}
                      />
                    )}
                    {f.note !== null && (
                      <FaInfoCircle className="ml-2 h-5 w-5" title={f.note} />
                    )}
                  </div>
                  {f.status === "FINISHED" && (
                    <div className="absolute right-2">
                      <ExternalLink
                        Icon={FaVideo}
                        path={getMatchLink(f)}
                        textClass=""
                        widthHeightClass="w-5 h-5"
                      />
                    </div>
                  )}
                </div>
              </Link>
            </div>
          );
        })
      )}
      <div className="flex h-12 justify-center items-center">
        <button
          className="w-40 font-bold text-xs"
          onClick={() => navigate(fixtureUrl)}
        >
          Tüm Fikstürü Görüntüle
        </button>
        <FaArrowRight className="ml-2 text-pink-600" />
      </div>
    </div>
  );
};

export default SimpleFixture;
