import React, { useState } from "react";
import { graphql } from "gatsby";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaHome,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";

import ExternalLink from "../../components/ExternalLink";
import { SeoComponent } from "../../components/SeoComponent";
import SimpleFixture from "../../components/SimpleFixture";
import SimpleStandings from "../../components/SimpleStandings";
import TabularButton from "../../components/TabularButton";
import { useAllMatches } from "../../hooks/useAllMatches";
import { useStandings } from "../../hooks/useStandings";
import { useStatNames } from "../../hooks/useStatNames";
import { getAge } from "../../util/dateUtil";
import { logos } from "../../util/logos";
import { countries } from "../../util/countries";
import {
  findFiveMatches,
  findFiveClosestStandings,
} from "../../util/standingsFixturesUtil";
import { statisticsCardNameMap } from "../../util/statisticsNameMap";

const teamCount = 19;

const Statistic = ({ label, value, rank, className, ascending }) => (
  <div
    className={`${className} flex mx-1 lg:mx-0 my-1 py-1.5 px-4 rounded-md text-md`}
  >
    <div className="flex-grow text-left">{label}</div>
    <div>
      {value}&nbsp;({ascending ? teamCount + 1 - rank : rank}/{teamCount})
    </div>
  </div>
);

const Team = (props) => {
  const { allMatches } = useAllMatches();
  const standings = useStandings();
  const team = props.data.teamsJson;
  const teamSquads = props.data.teamSquadsJson;

  const [showSquads, setShowSquads] = useState(false);

  const teamFixtureUrl = `/fikstur/takimlar/${team.slug}`;
  const fiveClosestMatches = findFiveMatches(allMatches, false, team.fullName);
  const fivePassedMatches = findFiveMatches(allMatches, true, team.fullName);
  const fiveClosestStandings = findFiveClosestStandings(
    standings,
    team.fullName
  );
  const textClass = `text-${team.slug}-2`;
  const backgroundClass = `bg-${team.slug}-1`;
  const statNames = useStatNames();
  const cellStyle =
    "flex items-center justify-center text-xs w-20 md:mr-3 xl:shrink-0";
  const nameStyle =
    "flex flex-grow text-center pl-4 lg:pl-0 items-center lg:justify-center text-xs md:w-48";
  const numberStyle =
    "flex justify-center items-center w-6 ml-4 mr-3 text-xs md:w-8";
  let darkGray = true;
  return (
    <div className="flex flex-col h-full w-full dark:bg-gray-900">
      <div className={`${backgroundClass} justify-center flex h-72`}>
        <div className="flex flex-col items-center w-full my-6">
          <div className="flex w-36 h-36 bg-white absolute rounded-full">
            <img
              width="100%"
              height="100%"
              src={logos[team.slug]}
              alt={team.fullName}
            />
          </div>
          <div className={`font-bold ${textClass} flex flex-col mt-24`}>
            <h1 className="text-2xl mt-16 flex justify-center">
              {team.fullName}
            </h1>
            <div className="flex text-base mt-4 justify-center">
              {team.website && (
                <ExternalLink
                  Icon={FaHome}
                  path={team.website}
                  textClass={textClass}
                />
              )}
              {team.facebook && (
                <ExternalLink
                  Icon={FaFacebook}
                  path={team.facebook}
                  textClass={textClass}
                />
              )}
              {team.twitter && (
                <ExternalLink
                  Icon={FaTwitter}
                  path={team.twitter}
                  textClass={textClass}
                />
              )}
              {team.youtube && (
                <ExternalLink
                  Icon={FaYoutube}
                  path={team.youtube}
                  textClass={textClass}
                />
              )}
              {team.instagram && (
                <ExternalLink
                  Icon={FaInstagram}
                  path={team.instagram}
                  textClass={textClass}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-center lg:mt-4 lg:text-center">
        <div className="lg:flex lg:ml-8 lg:basis-72 lg:h-full">
          <SimpleFixture
            header="Fikstür"
            fixture={fiveClosestMatches}
            fixtureUrl={teamFixtureUrl}
            showRounds={false}
          />
        </div>
        <div className="flex w-full mx-0 flex-col lg:mx-4 my-2 lg:mt-0 lg:w-2/5">
          <div className="flex justify-center">
            <TabularButton
              isActive={!showSquads}
              onClick={() => setShowSquads(false)}
              label="İstatistikler"
            />
            <TabularButton
              isActive={showSquads}
              onClick={() => setShowSquads(true)}
              label="Kadro"
            />
          </div>
          <div className="flex">
            {!showSquads && (
              <>
                {team.statistics ? (
                  <div className="flex flex-col w-full lg:h-full">
                    {statNames.map((stat) => {
                      const currentStat = team.statistics.total[stat.name];
                      return (
                        <Statistic
                          className={`${backgroundClass} ${textClass}`}
                          key={stat.name}
                          label={statisticsCardNameMap[stat.name].label}
                          rank={currentStat.rank}
                          value={currentStat.value}
                          ascending={stat.order === "asc"}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div>Henüz veri yok...</div>
                )}
              </>
            )}
            {showSquads && (
              <div className="flex flex-col w-full lg:h-full">
                <div className="flex flex-col border border-solid">
                  <div className="flex items-center bg-gray-200 h-12">
                    <div className="w-12"></div>
                    <div className={`${numberStyle} font-bold`}>#</div>
                    <div className={`${nameStyle} font-bold`}>Ad</div>
                    <div className={`${cellStyle} font-bold`}>Poz</div>
                    <div className={`${cellStyle} font-bold lg:mr-0`}>
                      Uyruk
                    </div>
                    <div
                      className={`${cellStyle} font-bold hidden sm:flex lg:hidden xl:flex`}
                    >
                      Yaş
                    </div>
                    <div
                      className={`${numberStyle} font-bold hidden sm:flex lg:hidden xl:flex`}
                    >
                      Boy
                    </div>
                  </div>
                  {teamSquads.squad.map((player) => {
                    darkGray = !darkGray;
                    const cellColor = Boolean(player.disabled)
                      ? "text-red-700"
                      : Boolean(player.information)
                      ? "text-green-700"
                      : `text-black`;
                    return (
                      <div
                        className={`flex items-center w-full border border-solid h-12 bg-gray-200 ${cellColor}`}
                        key={`${player.number}-${player.name}`}
                      >
                        <div className="flex ml-4 sm:basis-8 md:mr-1 justify-center">
                          <img
                            width="20px"
                            height="20px"
                            src={player.image}
                            alt={player.fullName}
                          />
                        </div>

                        <div className="flex flex-row justify-center items-center text-xs w-20 md:mr-3 xl:shrink-0">
                          <div
                            title={
                              Boolean(player.information)
                                ? player.information.explanation
                                : ""
                            }
                          >
                            {player.disabled && (
                              <FaArrowLeft className="text-red-700" />
                            )}
                            {!player.disabled && player.information && (
                              <FaArrowRight className="text-green-700" />
                            )}
                          </div>
                          <span className=" ml-2">{player.number}</span>
                        </div>

                        <span className={nameStyle}>{player.name}</span>

                        <span className={cellStyle}>{player.mainPosition}</span>
                        <div
                          className={`${cellStyle} font-bold lg:mr-0`}
                          title={player.nationality}
                        >
                          <img
                            width="20px"
                            height="20px"
                            src={countries[player.nationality]}
                            alt={player.nationality}
                          />
                        </div>

                        <span
                          className={`${cellStyle} font-bold lg:hidden hidden sm:flex  xl:flex font-normal`}
                        >
                          {getAge(new Date(player.birthDate))}
                        </span>
                        <span
                          className={`${numberStyle} font-bold lg:hidden  hidden sm:flex xl:flex font-normal`}
                        >
                          {player.height}
                        </span>
                      </div>
                    );
                  })}
                  {teamSquads.squad.length === 0 && (
                    <div>Güncel kadrolar yakında...</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:flex-col lg:mr-8 lg:basis-72 lg:mt-0 lg:h-full space-y-4">
          <SimpleStandings
            standings={fiveClosestStandings}
            teamToHighlight={team.fullName}
          />
          <SimpleFixture
            header="Maç Sonuçları"
            fixture={fivePassedMatches}
            fixtureUrl={teamFixtureUrl}
            showRounds={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;

export const query = graphql`
  query ($slug: String) {
    teamsJson(slug: { eq: $slug }) {
      id
      fullName
      shortName
      twitter
      website
      facebook
      youtube
      instagram
      storeSite
      slug
      statistics {
        total {
          avgBallPossession {
            rank
            value
          }
          blockedShots {
            rank
            value
          }
          cornerKicks {
            rank
            value
          }
          fouls {
            rank
            value
          }
          ftAgainst {
            rank
            value
          }
          ftFor {
            rank
            value
          }
          goalkeeperSaves {
            rank
            value
          }
          htAgainst {
            rank
            value
          }
          htFor {
            rank
            value
          }
          offsides {
            rank
            value
          }
          avgPassAccuracy {
            rank
            value
          }
          passesAccurate {
            rank
            value
          }
          redCards {
            rank
            value
          }
          shotsInsideBox {
            rank
            value
          }
          shotsOffGoal {
            rank
            value
          }
          shotsOnGoal {
            rank
            value
          }
          shotsOutsideBox {
            rank
            value
          }
          totalMatches {
            rank
            value
          }
          totalPasses {
            rank
            value
          }
          totalShots {
            rank
            value
          }
          yellowCards {
            rank
            value
          }
          avgXg {
            rank
            value
          }
          xgPerGoal {
            rank
            value
          }
          xgPerShot {
            rank
            value
          }
          goalPerShotsOnTarget {
            rank
            value
          }
          savePercentage {
            rank
            value
          }
          blockedShotsRatio {
            rank
            value
          }
          onTargetShotsRatio {
            rank
            value
          }
          goalsPerShot {
            rank
            value
          }
        }
      }
    }
    teamSquadsJson(slug: { eq: $slug }) {
      fullName
      squad {
        birthDate
        height
        image
        mainPosition
        name
        nationality
        number
        page
        disabled
        information {
          reason
          date
          explanation
        }
      }
      slug
      shortName
    }
  }
`;

export const Head = ({ data }) => {
  const { fullName } = data.teamsJson;
  return (
    <SeoComponent
      title={`${fullName}`}
      description={`Türkiye Futbol Süper Ligi 2024-25 Sezonu ${fullName} puan durumu, fikstürü ve istatistikleri`}
      keywords={`${fullName}, maç sonuçları, mac sonuclari, fikstür, fikstur, puan durumu,
      Adana Demirspor, Alanyaspor, Antalyaspor, Başakşehir, Basaksehir, Beşiktaş, Besiktas, Bodrumspor, Eyüpspor, Eyupspor,
      Galatasaray, Fenerbahçe, Gaziantep, Gaziantep FK, Goztepe, Göztepe, Hatayspor, Kasımpaşa, Kasimpasa, Konyaspor,
      Rizespor, Sivasspor, Trabzonspor, Süperlig, Superlig, ensuperlig, ensüperlig`}
    />
  );
};
